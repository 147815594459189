import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loadLanguages, setCurrentLanguage } from "redux/actions/languageActions";
import i18n from "../../i18n";
import * as lg from "../../shared/images";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const LanguageSwitch = () => {
 
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const languages = useSelector(state => state.languages.languages);
  const currentLanguage = useSelector(state => state.languages.currentLanguage);

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(loadLanguages());
    }
    
    const lng = localStorage.getItem("language");
    if (lng) {
      handleLanguageChange(lng, false);
    } else {
      const defaultLanguage = "fr";
      handleLanguageChange(defaultLanguage, false);
    }
  }, []);
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLanguageChange = (language, saveToLocalStorage = true) => {
    dispatch(setCurrentLanguage(language));
    setOpen(false);
    i18n.changeLanguage(language).then(() => {
      if (saveToLocalStorage) {
        localStorage.setItem("language", language);
      }
    });
  };
  
  const selectedLanguage = useMemo(() => {
    return languages.find(lang => lang.code === currentLanguage);
  }, [languages, currentLanguage]);

  // // // console.log('languages :>>', languages);
  // // // console.log('currentLanguage :>>', currentLanguage);

  return (
    <div className={classes.root}>
      <IconButton sx={{mr:2}} ref={anchorRef} onClick={handleToggle} aria-controls={open ? "menu-list-grow" : undefined}>
        <img
          loading="lazy"
          width="24"
          src={`${lg[selectedLanguage?.code.toLowerCase()]}`}
          alt=""
        />
      </IconButton>
      {/* <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <LanguageIcon className={classes.icon} />
        {selectedLanguage?.description || "English"}
      </Button> */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper sx={{ mt: 0.5 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow" sx={{ zIndex: 99999 }}>
                    {languages.filter(l => l.isActif).map((language) => {
                      const activeLg = languages.find((lang) => lang.code === currentLanguage);
                      const isSelected = language?.code === activeLg?.code
                      // // // console.log('language?.code :>>', language?.code, selectedLanguage.code);
                      // // // console.log('test :>>',language?.code, test.code, language?.code===test.code);
                      return(
                      
                      <MenuItem
                        key={language.code}                      
                        selected={isSelected}
                        onClick={() => handleLanguageChange(language.code)}
                        style={{paddingLeft:10,
                          background: isSelected ? '#ede7f6' : ''
                          // '&:selected': {backgroundColor: "red"}
                        }}
                      >
                        <img style={{marginRight:15}}
                          loading="lazy"
                          width="19"
                          src={`${lg[language.code.toLowerCase()]}`}
                          alt=""
                        />
                        <Typography variant="body2">{language.description}</Typography>
                        
                      </MenuItem>
                    )}
                    )}
                  </MenuList>
                {/* <MenuList id="menu-list-grow">
                  {languages?.map((language) => (
                    <MenuItem
                      key={language.code}
                      active={language.code === currentLanguage}
                      onClick={() => handleLanguageChange(language.code)}
                    >
                      {t(language.description)}
                    </MenuItem>
                  ))}
                </MenuList> */}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default LanguageSwitch;
